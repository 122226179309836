import React, { useEffect } from 'react'

const Tournament: React.FC = () => {
  useEffect(() => {
    const url = window.location.href

    if (url.includes('cards')) {
      window.location.href = 'https://download-app-cards.getmega.com'
    }

    if (url.includes('rummy')) {
      window.location.href = 'https://download-app-rummy.getmega.com'
    }

    if (url.includes('trivia')) {
      window.location.href = 'https://download-app1.web.app/'
    }
  }, [])

  return null
}

export default Tournament
